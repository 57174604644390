import Image from 'next/image';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useBoolean } from 'usehooks-ts';

const sanitizeColor = (color: number) => Math.min(255, Math.max(0, color));

export const lightenColor = (color: string, percent: number) => {
  const num = parseInt(color, 16);
  const amt = Math.round(2.55 * percent);
  const R = sanitizeColor((num >> 16) + amt); // eslint-disable-line no-bitwise
  const B = sanitizeColor(((num >> 8) & 0x00ff) + amt); // eslint-disable-line no-bitwise
  const G = sanitizeColor((num & 0x0000ff) + amt); // eslint-disable-line no-bitwise

  return (0x1000000 + R * 0x10000 + B * 0x100 + G).toString(16).slice(1);
};

export const Color = ({ collection }: { collection: any }) => {
  const backgroundColor = useMemo(() => {
    const dk = collection.header_background.replace('#', '');
    const lt = lightenColor(dk, 25);
    return `radial-gradient(58.66% 71.5% at 64.93% 27.87%, #${lt} 24.72%, #${dk} 100%)`;
  }, [collection.header_background]);
  const { value: readMore, setValue: setReadMore } = useBoolean(false);

  return (
    <div
      className="flex flex-col items-center gap-x-2 rounded p-4 text-left sm:flex-row sm:px-8 sm:py-6"
      style={{ background: backgroundColor }}
    >
      <div className="flex w-full flex-col gap-y-2 text-left">
        <h1 className="font-header text-2xl font-bold text-white sm:text-3xl">
          {collection.title}
        </h1>
        {collection.subheader && (
          <h3 className="text-lg text-white">{collection.subheader}</h3>
        )}
        {collection.description && (
          <div
            className={`mt-2 text-white ${readMore ? '' : 'max-sm:hidden'}`}
            dangerouslySetInnerHTML={{ __html: collection.description }}
          />
        )}
      </div>
      <button
        className="mx-[-48px] mt-2 w-full border-t border-t-white/30 pt-2 text-sm font-thin text-white sm:hidden"
        onClick={() => setReadMore(!readMore)}
      >
        Read more
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`ml-1 opacity-30 transition duration-75 ${
            readMore ? 'rotate-180' : ''
          }`}
        />
      </button>
      {collection.header_image && (
        <Image
          src={`${collection.header_image}`}
          alt={collection.title}
          unoptimized
          fill
          className="!relative max-h-[180px] !w-auto max-lg:hidden"
        />
      )}
    </div>
  );
};
