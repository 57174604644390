import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useState } from 'react';

export interface TableOfContentItemProp {
  label: string;
  sublabel?: string;
  url?: string;
  id?: string;
  children?: TableOfContentItemProp[];
}

export interface TableOfContentProps {
  title: string;
  items: TableOfContentItemProp[];
  enableCollapse?: boolean;
}

/**
 * TableOfContent
 * Component to display a list of content with links to scroll directly to that section,
 * or if any link is provider it will redirect to that link.
 * This component will behave as collapsible on mobile.
 *
 * @items {TableOfContentItemProp[]} - List of links (with any nested links) { label, url, id, items }
 * @title {string} - Title for the table of content
 */
function TableOfContent({
  title,
  items,
  enableCollapse = false,
}: TableOfContentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentHash, setCurrentHash] = useState('');

  useEffect(() => {
    function hashChanged() {
      setCurrentHash(window.location.hash);
    }

    window.addEventListener('hashchange', hashChanged);
    hashChanged();

    return () => {
      window.removeEventListener('hashchange', hashChanged);
    };
  }, []);

  const toggleOpen = useCallback(() => {
    if (enableCollapse) {
      setIsOpen(false);
    }
  }, [enableCollapse]);

  const itemChildren = useCallback(
    (children: TableOfContentItemProp[]) => {
      return (
        <ul className="space-y-1 pl-2">
          {children.map((child: TableOfContentItemProp) => (
            <li key={child.id} className="space-y-1">
              <a
                className={`transition-colors hover:text-bolt ${
                  currentHash === `#${child.id}` ? 'text-bolt' : ''
                }`}
                href={child.url || `#${child.id}`}
                onClick={() => toggleOpen()}
              >
                {child.label}
              </a>
              {child.children && itemChildren(child.children)}
            </li>
          ))}
        </ul>
      );
    },
    [currentHash, toggleOpen],
  );

  const itemsList = useMemo(
    () =>
      items.map((item) => (
        <li key={item.id} className="space-y-1">
          <a
            className={`font-medium transition-colors hover:text-bolt ${
              currentHash === `#${item.id}` ? 'text-bolt' : ''
            }`}
            href={item.url || `#${item.id}`}
            onClick={() => toggleOpen()}
          >
            {item.label}
            {item.sublabel && (
              <span className="text-sm font-normal text-midnight">{` ${item.sublabel}`}</span>
            )}
          </a>
          {item.children && itemChildren(item.children)}
        </li>
      )),
    [items, currentHash, itemChildren, toggleOpen],
  );

  return (
    <div
      className={`border border-sundog ${!enableCollapse ? 'md:rounded' : ''}`}
    >
      <div
        className={`relative flex items-center justify-between gap-4 border-b border-sundog px-4 py-2 font-medium ${
          enableCollapse ? 'bg-iceberg shadow-sm' : ''
        }`}
      >
        {title}

        {enableCollapse && (
          <button
            className='focus:outline-none after:absolute after:inset-0 after:content-[""]'
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="sr-only">Toggle table content</span>
            <FontAwesomeIcon
              icon={faChevronUp}
              className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}
            />
          </button>
        )}
      </div>

      {!!items.length && (
        <ul
          className={`my-4 max-h-96 space-y-4 overflow-y-auto overscroll-contain ${
            isOpen || !enableCollapse ? 'block px-4' : 'hidden max-md:pb-0'
          }`}
        >
          {itemsList}
        </ul>
      )}
    </div>
  );
}

export default TableOfContent;
