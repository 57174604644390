import { memo, useState, useRef, useEffect } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Heading } from '@appsumo/dorado-react';

import ChevronDown from '~/public/font-awesome/chevron-down-bolt.png';

const CHEVRON_ICON_SIZE = 16;

export const MonoTone = memo(function MonoTone({
  collection,
}: Readonly<{ collection: any }>) {
  const descRef = useRef<HTMLInputElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isGreaterThanMaxHeight, setIsGreaterThanMaxHeight] = useState(false);

  const iconClassName = clsx(
    'ml-2 inline-block text-right transition duration-100 ease-in-out',
    !isCollapsed && 'rotate-180',
  );

  // Invisible was used to reduce layout shift when showing/hiding the button
  // The gap between the description and the skus are an acceptible tradeoff
  const buttonClassName = clsx(
    'mx-auto mt-2 text-center sm:hidden',
    !isGreaterThanMaxHeight && 'invisible',
  );
  const buttonText = isCollapsed ? 'Read more' : 'Read less';

  // This doesn't account for where the user changes the window size. We should not do this unless it's necessary
  // since it will require tracking the window size and re-rendering the component
  useEffect(() => {
    if (!descRef?.current?.scrollHeight) return;

    setIsGreaterThanMaxHeight(
      descRef.current.scrollHeight > descRef.current.clientHeight,
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mx-auto max-w-[800px] md:my-8">
      <div className="flex items-center justify-center gap-x-4">
        {collection.header_image && (
          <Image
            src={`${collection.header_image}`}
            alt={collection.title}
            unoptimized
            fill
            className="!relative !h-[40px] !w-auto"
          />
        )}
        <Heading.H1>{collection.title}</Heading.H1>
      </div>
      {collection.description && (
        <div
          className={`mt-2 ${isCollapsed ? 'max-sm:line-clamp-3' : ''}`}
          dangerouslySetInnerHTML={{ __html: collection.description }}
          ref={descRef}
        />
      )}
      <div className={buttonClassName}>
        <button
          className="text-bolt"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {buttonText}
          <Image
            src={ChevronDown}
            alt="expand"
            width={CHEVRON_ICON_SIZE}
            height={CHEVRON_ICON_SIZE}
            className={iconClassName}
          />
        </button>
      </div>
    </div>
  );
});
