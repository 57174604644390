import Image from 'next/image';
import { Link } from '@appsumo/dorado-react';
import { EmailCapture } from '~/components/global/EmailCapture';
import imageUrl from '../public/email-capture-notfound.gif';
import useUser from '~/lib/user';
import { GA4FormContentValue } from '~/lib/util/constants';

export default function Custom404() {
  const { user } = useUser();

  return (
    <div className="flex h-[686px] flex-col items-center justify-center overflow-hidden bg-cover py-4 text-white sm:h-[778px] md:h-[804px] md:py-6 lg:h-[470px]  lg:bg-[image:var(--background-image)]">
      <div className="flex h-auto flex-col-reverse items-center gap-y-4 text-black sm:items-start sm:gap-x-[70px] lg:flex-row">
        <div className="flex shrink-0 flex-col gap-y-4 px-4 text-left sm:max-w-lg sm:text-left lg:my-4 lg:w-5/6">
          <h5 className="text-4xl font-bold">404</h5>
          <p className="text-left text-2xl font-bold text-black line-clamp-2">
            Weird, we couldn&rsquo;t find that page
          </p>
          <p className="text-black line-clamp-2 sm:text-lg lg:w-[550px]">
            {user ? (
              <>
                Sorry, but the page you&rsquo;re looking for doesn&rsquo;t
                exist. See our latest deals
                <Link className="sm: relative left-1 lg:top-0" href="/" styled>
                  here
                </Link>
              </>
            ) : (
              <>
                But sign up for our emails to find great deals and get{' '}
                <b>10% off</b> your first purchase:
              </>
            )}
          </p>
          {!user && (
            <EmailCapture
              content={GA4FormContentValue.spotlight404Page10Off}
              componentName="spotlight-email-capture"
              className="flex flex-col gap-y-4 text-left text-black md:max-w-sm"
              submitText="Get 10% off"
              inputLabelClasses="text-black font-semibold font-header"
              link={{ name: 'No thanks, browse all deals', ref: '/browse' }}
            />
          )}
        </div>
        <div className="w-full pl-4">
          <Image src={imageUrl} alt="img" className="w-48 lg:w-72" />
        </div>
      </div>
    </div>
  );
}
