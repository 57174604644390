import Image from 'next/image';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DealSkuCardType } from '~/components/sku';
import { Price, TopFeatures } from '~/components/sku/AlternativeTo';
import { Roadblock } from '~/components/sku/Roadblock';
import { Heading } from '~/components/ui';
import Accordion from '~/components/ui/Accordion';
import ProductSummaryCard from '~/components/ui/ProductSummaryCard';
import TableOfContent, {
  TableOfContentItemProp,
} from '~/components/ui/TableOfContent';
import { QuestionV2 } from '~/lib/discussions/types';
import useUser from '~/lib/user';
import { Attribute } from '~/types/deal';

interface DealProps {
  id: string | number;
  public_name: string;
  media_url: string;
  sku: {
    price: number;
  };
  story: {
    card_description: string;
    highlights: { highlight: string }[];
    snippet?: string;
    snippet_seo?: string;
    bottom_header?: string;
  };
  common_features?: { text: string }[];
  features?: { feature: string }[];
}

interface DealSourceProps extends DealProps {
  attributes?: Attribute[];
}

interface AlternativeCollectionProps {
  template_slug: string;
  data_source_object: DealSourceProps;
  deals: DealProps[];
  title: string;
  subheader: string;
  description: string;
}

export interface AdditionalDataProps {
  introduction: string;
  how_we_made: string;
  best_alternative: string;
  how_to_chose: string;
  faqs: {
    question: string;
    answer: string;
  }[];
  compare: [
    {
      product: string;
      price: number;
      best_for: string;
      standout_feature: string;
    },
  ];
}

export const Alternative = ({
  collection,
  deals,
  questions,
  additionalData,
}: {
  collection: AlternativeCollectionProps;
  deals: DealSkuCardType[];
  questions: QuestionV2[];
  additionalData?: AdditionalDataProps;
}) => {
  const { data_source_object: deal } = collection;
  const [now] = useState(new Date());
  const [enableTableOfContentCollapse, setEnableTableOfContentCollapse] =
    useState(false);

  const { user, isLoading: userLoading } = useUser();

  const faqItems = useMemo(
    () =>
      additionalData?.faqs?.map((faq: any) => ({
        title: faq.question,
        content: faq.answer,
      })) || [],
    [additionalData],
  );

  const tableContentItems: TableOfContentItemProp[] = useMemo(() => {
    return deals?.map((deal) => ({
      id: `summary-${deal.slug}`,
      label: deal.public_name,
      sublabel:
        (deal?.attributes?.best_for || []).length > 0
          ? `for ${deal?.attributes?.best_for?.join(', ')}`
          : '',
    }));
  }, [deals]);

  const toggleTableOfContentCollapse = useCallback((e: MediaQueryListEvent) => {
    setEnableTableOfContentCollapse(e.matches);
  }, []);

  useEffect(() => {
    const mobileQuery = window.matchMedia('(max-width: 1024px)');

    setEnableTableOfContentCollapse(mobileQuery.matches);
    mobileQuery.addEventListener('change', toggleTableOfContentCollapse);

    return () => {
      mobileQuery.removeEventListener('change', toggleTableOfContentCollapse);
    };
  }, [toggleTableOfContentCollapse]);

  if (!deal) return <></>;

  return (
    <div className="my-8 md:my-12">
      <div>
        <div className="mx-auto flex max-w-3xl flex-col gap-y-12 max-sm:px-4 ">
          <Heading.H1 className="text-center md:text-[42px] lg:leading-none">
            {collection.title} ({new Date().getFullYear()})
          </Heading.H1>

          <div className="flex flex-col gap-4 sm:gap-y-8">
            <div className="aspect-video w-full overflow-hidden rounded shadow">
              <Image
                src={`${deal.media_url}?width=850&height=470`}
                width={850}
                height={470}
                unoptimized
                alt={`The best ${deal.public_name} alternatives`}
                className="aspect-video w-full object-cover"
                priority
              />
            </div>
            <div>
              {additionalData?.introduction && (
                <div
                  className="space-y-4 break-words text-lg leading-8 text-grace"
                  dangerouslySetInnerHTML={{
                    __html: additionalData?.introduction,
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="mt-12 space-y-16">
          {additionalData?.how_we_made && (
            <div className="mx-auto max-w-3xl space-y-4 border-t border-sundog pt-8 max-sm:px-4">
              <h2 className="font-header text-2xl font-bold lg:text-3xl">
                How we made the list
              </h2>

              <div
                className="space-y-4 break-words text-lg leading-8 text-grace"
                dangerouslySetInnerHTML={{
                  __html: additionalData?.how_we_made,
                }}
              />
            </div>
          )}

          <div className="mx-auto max-w-3xl max-sm:px-4">
            <TableOfContent
              title="Table of Contents"
              items={tableContentItems}
              enableCollapse={enableTableOfContentCollapse}
            />
          </div>

          <div className="mx-auto max-w-3xl space-y-4 max-sm:px-4">
            <h2 className="font-header text-2xl font-bold lg:text-3xl">
              Compare alternatives to {deal.public_name}
            </h2>

            <div className="overflow-x-auto rounded border border-b-0 border-sundog">
              <table className="w-full table-fixed border-spacing-0 overflow-hidden rounded">
                <colgroup>
                  <col span={1} style={{ width: '205px' }} />
                  <col span={1} style={{ width: '205px' }} />
                  <col span={1} style={{ width: '205px' }} />
                  <col span={1} style={{ width: '205px' }} />
                </colgroup>
                <thead>
                  <tr className="bg-iceberg">
                    <th className="py-5 px-3 text-left text-sm font-normal">
                      <span className="sr-only">Deal</span>
                    </th>
                    <th className="py-5 px-3 text-left text-sm font-normal">
                      Price
                    </th>
                    <th className="py-5 px-3 text-left text-sm font-normal">
                      Best for
                    </th>
                    <th className="py-5 px-3 text-left text-sm font-normal">
                      Standout Feature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deals?.map((deal) => (
                    <tr key={deal.id} className="border-y border-gravel">
                      <td className="py-5 px-3">
                        <Link
                          href={`/products/${deal.slug}`}
                          className="text-bolt hover:underline"
                          target="_blank"
                        >
                          {deal.public_name}
                        </Link>
                      </td>
                      <td className="py-5 px-3">
                        <Price
                          price={deal?.price}
                          planTypes={deal?.unique_plan_types}
                        />
                      </td>
                      <td className="py-5 px-3">
                        {deal?.attributes?.best_for?.join(', ')}
                      </td>
                      <td className="py-5 px-3">
                        <TopFeatures features={deal.common_features} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="bg-iceberg py-16 max-sm:px-4">
            <div className="mx-auto max-w-3xl space-y-2">
              <Heading.H2 className="lg:text-3xl">
                Top {deals?.length} Alternatives to {deal.public_name} in{' '}
                {now.getFullYear()}
              </Heading.H2>

              <div
                className="space-y-4 leading-6 text-grace"
                dangerouslySetInnerHTML={{ __html: collection.description }}
              />

              <div className="!mt-8 space-y-12">
                {deals?.map((deal) => (
                  <ProductSummaryCard
                    key={deal.id}
                    deal={deal as any}
                    question={questions.find(
                      (question) => question?.deal_id === deal.id,
                    )}
                  />
                ))}
              </div>
            </div>
          </div>

          {additionalData?.best_alternative && (
            <div className="mx-auto max-w-3xl space-y-4 max-sm:px-4">
              <h2 className="font-header text-2xl font-bold lg:text-3xl">
                What makes the best {deal.public_name} alternatives
              </h2>

              <div
                className="space-y-4 break-words text-lg leading-8 text-grace"
                dangerouslySetInnerHTML={{
                  __html: additionalData.best_alternative,
                }}
              />
            </div>
          )}

          {additionalData?.how_to_chose && (
            <div className="mx-auto max-w-3xl space-y-4 max-sm:px-4">
              <h2 className="font-header text-2xl font-bold lg:text-3xl">
                How to choose the best {deal.public_name} alternatives
              </h2>

              <div
                className="space-y-4 break-words text-lg leading-8 text-grace"
                dangerouslySetInnerHTML={{
                  __html: additionalData.how_to_chose,
                }}
              />
            </div>
          )}

          {faqItems.length > 0 && (
            <div className="mx-auto max-w-3xl max-sm:px-4">
              <h2 className="font-header text-2xl font-bold lg:text-3xl">
                FAQ
              </h2>

              <Accordion items={faqItems} initialIndex={0} />
            </div>
          )}
        </div>
      </div>

      {!userLoading && !user?.email && (
        <div className="mt-12 w-full max-lg:px-4 lg:fixed lg:top-36 lg:right-8 lg:max-w-xs">
          <Roadblock
            type="alternative-to-roadblock"
            componentName="alternative-to-roadblock"
            key="alternative-to-roadblock"
          />
        </div>
      )}
    </div>
  );
};
