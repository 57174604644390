import { useMemo } from 'react';
import useSWR from 'swr';
import { Button } from '@appsumo/dorado-react';

import { useSavedCart } from '~/lib/cart/useCart';
import { fetchJson } from '~/lib/fetch';

import { lightenColor } from './Color';

const getWishlistDataUrl = (templateSlug: string) =>
  `/api/smartcollections/wishlists/header_details/?template_slug=${templateSlug}`;

const getUsernameAbreviation = (userName: string): string => {
  if (userName && userName.length >= 2) {
    const names = userName.split(' ');

    if (names.length > 1) {
      const abv = names[0].slice(0, 1) + names[1].slice(0, 1);
      return abv.toUpperCase();
    }
    return userName.slice(0, 2).toUpperCase();
  }
  return 'UN';
};

export const Wishlist = ({ collection }: { collection: any }) => {
  const { moveAllToCart } = useSavedCart();

  const backgroundColor = useMemo(() => {
    const dk = collection.header_background.replace('#', '');
    const lt = lightenColor(dk, 25);
    return `radial-gradient(58.66% 71.5% at 64.93% 27.87%, #${lt} 24.72%, #${dk} 100%)`;
  }, [collection.header_background]);

  // need to fetch specific wishlist header data, like the owner and if the list is private
  const { template_slug: templateSlug } = collection;
  const wishlistDataUrl = getWishlistDataUrl(templateSlug);
  // Update if the user changes their wishlist in another tab
  const { data: wishlistData } = useSWR(wishlistDataUrl, fetchJson, {
    revalidateOnFocus: true,
  });

  return (
    <div
      className="flex flex-col p-4 sm:px-8 sm:py-6"
      style={{ background: backgroundColor }}
    >
      {/* Wishlist Owner Info */}
      <div className="flex w-full flex-row gap-x-2 leading-8 text-white">
        <div className="h-8 w-8 rounded-full bg-spruce text-center">
          {getUsernameAbreviation(wishlistData?.user_name)}
        </div>
        <div className="ml-2">{wishlistData?.user_name || 'Sumo-ling'}</div>
      </div>
      <div className="flex flex-col items-center justify-between gap-2 rounded text-left sm:flex-row">
        <div className="flex flex-col gap-y-2 text-left">
          <h1 className="w-full font-header text-2xl font-bold text-white sm:text-3xl">
            {collection.title}
          </h1>
          {collection.description && (
            <div
              className="mt-2 text-white"
              dangerouslySetInnerHTML={{ __html: collection.description }}
            />
          )}
        </div>
        <Button
          onClick={() => {
            moveAllToCart(wishlistData?.id).then(() => {
              window.location.href = '/cart/';
            });
          }}
        >
          Add all products to cart
        </Button>
      </div>
    </div>
  );
};
