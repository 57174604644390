import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { useRouter } from 'next/router';
import useSWRImmutable, { SWRConfig } from 'swr';

import { DEALATTRIBUTE_PERSONAL_URL } from '~/lib/attributes/constants';
import useAttributes from '~/lib/attributes/hook';
import { extractCollection, getDeals } from '~/lib/browse/fetch';
import { fetchJson } from '~/lib/fetch';
import useUser from '~/lib/user';

import Browse from '~/components/browse/Browse';
import { CollectionSeo } from '~/components/seo';
import {
  AdditionalDataProps,
  Alternative,
} from '~/components/smartcollections/headers/Alternative';
import { Color } from '~/components/smartcollections/headers/Color';
import { MonoTone } from '~/components/smartcollections/headers/MonoTone';
import { Wishlist } from '~/components/smartcollections/headers/Wishlist';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { getDiscussionsUrlV2 } from '~/lib/discussions/fetch';
import { DiscussionQuestion } from '~/lib/discussions/types';
import { Deal } from '~/types/deal';
import Custom404 from '../404';

const getCollectionUrl = (collectionSlug: string, templateSlug = '') =>
  `/api/smartcollections/${collectionSlug}/?include[]=open_graph&template_slug=${templateSlug}`;

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const { slug } = params as { slug: string[] };
  const dealAttributes = await fetchJson(DEALATTRIBUTE_PERSONAL_URL);
  const initialFilters = extractCollection({
    slug,
    dealAttributes,
  });
  const { attributes = {} } = initialFilters;
  const { collectionSlug, templateSlug } = attributes;
  const collectionUrl = getCollectionUrl(collectionSlug, templateSlug);

  try {
    const data = await fetchJson(collectionUrl);
    const { smart_collection: collection } = data;

    const profileParams = (collection.query_parameters as string)
      .split('&')
      .reduce((acc: Record<string, string>, cur) => {
        const [key, value] = cur.split('=');
        acc[key] = value;
        return acc;
      }, {});

    const deals = collection.per_user_cache
      ? null
      : await getDeals(
          initialFilters,
          profileParams,
          collection.header_type === 'alternative'
            ? collection.maximum_results || 20
            : 20,
        );

    let questions: DiscussionQuestion[] = [];
    let additionalData: AdditionalDataProps | null = null;

    if (collection.header_type === 'alternative') {
      const questionsFetches = deals?.deals?.map((deal: Deal) => {
        const url = getDiscussionsUrlV2({
          dealId: deal.id,
          type: DEAL_COMMENT_TYPE.QUESTION,
          sort: 'helpful',
          itemsPerPage: 1,
        });

        return fetchJson(url);
      });

      if (questionsFetches) {
        questions = await Promise.all(questionsFetches);
      }

      try {
        const response = await fetchJson(
          `/utils/sumo-dictionary/?slug=${templateSlug}`,
        );
        additionalData = response[0].data;
      } catch (error) {
        console.log('Error fetching additional data:', error);
      }
    }

    return {
      props: {
        collectionUrl,
        deals,
        questions,
        additionalData,
        fallback: {
          [DEALATTRIBUTE_PERSONAL_URL]: dealAttributes,
          [collectionUrl]: data,
        },
      },
      revalidate: collection.per_user_cache ? false : 240,
    };
  } catch (error) {
    console.log('Error fetching collection:', error);
    return {
      props: {
        collectionUrl,
        fallback: {
          [DEALATTRIBUTE_PERSONAL_URL]: dealAttributes,
        },
      },
      revalidate: false,
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

type CollectionPageProps = InferGetStaticPropsType<typeof getStaticProps>;

const CollectionPage: React.FC<CollectionPageProps> = ({
  collectionUrl,
  deals,
  questions,
  additionalData,
  fallback = {},
}) => {
  const router = useRouter();
  const { user } = useUser();

  const { data: dealAttributes } = useAttributes({
    fallbackData: fallback[DEALATTRIBUTE_PERSONAL_URL],
  });

  const { data, error, mutate } = useSWRImmutable(collectionUrl, {
    fallbackData: fallback[collectionUrl],
  });

  if (error) {
    return <Custom404 />;
  }

  if (!data) {
    return <></>;
  }

  const { smart_collection: collection } = data || {};

  if (!collection) {
    return <Custom404 />;
  }

  const profileParams = (collection.query_parameters as string)
    .split('&')
    .reduce((acc: Record<string, string>, cur) => {
      const [key, value] = cur.split('=');
      acc[key] = value;
      return acc;
    }, {});

  const initialFilters = extractCollection({
    slug: router.query.slug as string[],
    dealAttributes,
    query: { ...profileParams, ...(router.query as Record<string, string>) },
  });

  initialFilters.maximum_results = collection.maximum_results;

  return (
    <SWRConfig
      value={{
        fallback,
      }}
    >
      <CollectionSeo collection={collection} />

      {collection.header_type === 'alternative' && (
        <Alternative
          collection={collection}
          deals={deals ? deals.deals : undefined}
          additionalData={additionalData}
          questions={questions?.map(
            ({ comments }: DiscussionQuestion) => comments[0] ?? null,
          )}
        />
      )}

      {collection.header_type !== 'alternative' && (
        <Browse
          initialFilters={initialFilters}
          fallbackData={deals ? [deals] : undefined}
          profileParams={profileParams}
          fullWidth={collection.browse_full_width}
          skuCard={collection.sku_card_type}
          layout={collection.browse_layout}
          showProductCount={collection.show_product_count}
          showSort={collection.show_sort}
          showFilters={collection.show_filters}
          templateSlug={collection.template_slug}
          userId={(collection.per_user_cache && user?.id) || undefined}
          mutateCollection={collection.enable_deal_voting ? mutate : undefined}
          enableVoting={collection.enable_deal_voting}
          bottomHeader={collection?.data_source_object?.story?.bottom_header}
          isCollectionPage
        >
          <div className="mb-5 text-center">
            {collection.header_type === 'color' && (
              <Color collection={collection} />
            )}
            {collection.header_type === 'monotone' && (
              <MonoTone collection={collection} />
            )}
            {collection.header_type === 'wishlist' && (
              <Wishlist collection={collection} />
            )}
          </div>
        </Browse>
      )}
    </SWRConfig>
  );
};

export default CollectionPage;
